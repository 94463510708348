import React, { Component } from 'react';

class IconBoxes extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        let iconData = {
            RWERT : null,
            BARFUSSWERT: null,
            ABRIEB: null,
            STAERKE: null,
            VARIATIONEN: null,
            FLECKBESTAENDIGKEIT: null
        }

        this.props.icons.map((iconObj, i) => {
            if (iconObj.type == 'FLECKBESTAENDIGKEIT') {
                iconData.FLECKBESTAENDIGKEIT = iconObj.value;
            }else if(iconObj.type == 'ABRIEB'){
                iconData.ABRIEB = iconObj.value;
            }else if(iconObj.type == 'STAERKE'){
                iconData.STAERKE = iconObj.value;
            }else if(iconObj.type == 'VARIATIONEN'){
                iconData.VARIATIONEN = iconObj.value;
            }else if(iconObj.type == 'RUTSCHFESTIGKEIT'){
                iconData.RWERT = iconObj.value['R_WERT'];
                iconData.BARFUSSWERT = iconObj.value['BARFUSSWERT'];
            }
        });

        return (
            <div>
                {(iconData.RWERT || iconData.BARFUSSWERT || iconData.ABRIEB) &&(
                    <div className={'iconbox'}>
                        <img src={'/icons/icon_rutschfestigkeit.png'} height={30} />
                        {iconData.RWERT}{iconData.RWERT?" ":""}
                        {iconData.BARFUSSWERT}{iconData.BARFUSSWERT?" ":""}
                        {iconData.ABRIEB}
                    </div>
                )}
                {(iconData.STAERKE) &&(
                    <div className={'iconbox'}>
                        <img src={'/icons/icon_staerke.png'} height={30} /> {iconData.STAERKE}
                    </div>
                )}
                {(iconData.VARIATIONEN) &&(
                    <div className={'iconbox'}>
                        <img src={'/icons/icon_variationen.png'} height={30} /> {iconData.VARIATIONEN}
                    </div>
                )}
                {(iconData.FLECKBESTAENDIGKEIT) &&(
                    <div className={'iconbox'}>
                        <img src={'/icons/icon_fleck.png'} height={30} /> {iconData.FLECKBESTAENDIGKEIT}
                    </div>
                )}
            </div>
        );
    }
}


export default IconBoxes;