import React, {Component, useState} from 'react';


import config from "./../config";
import {Navigate} from "react-router-dom";
import logo from "../assets/kdb-logo.jpeg";


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDolly, faDollyFlatbed,
    faEuro,
    faFileCsv, faFileLines,
    faFilePdf, faHashtag,
    faImage, faMessage,
    faQrcode,
    faTextSlash,
    faTextWidth,
    faUser
} from '@fortawesome/free-solid-svg-icons'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


// import required modules
import { Pagination, Navigation } from "swiper";
// or less ideally
import {Button, Col, Container, Row, Stack, Tab, Tabs} from 'react-bootstrap';
import IconBoxes from "./IconBoxes";
class Product extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        function capitalizeFirstLetter(string) {
            return string.toLowerCase().charAt(0).toUpperCase() + string.toLowerCase().slice(1);
        }

        var uniqueId = this.props.product.uniqueId;

        var productTitleTextLines = this.props.product.human_readable.web_text_lines;
        return (
            <div className="productsTableBody">
                <Container>
                    <Row>
                        <Col xs={12} lg={3} md={4} className="mb-3 mb-sm-0">
                            <p className="text-md-start text-center ">
                                <img src={this.props.product.corporateIdentity.logoLandscape} style={{height:"5em",maxWidth:"90%"}} alt="logo"/>
                            </p>
                        </Col>
                        <Col xs={12} lg={9} md={8} className="mb-3 mb-sm-0">
                            {!this.props.product.corporateIdentity.showPrice &&
                            <Row>
                                <Col xs={12} md={12}>
                                    <div>
                                        <h3>
                                        {productTitleTextLines.map((textLine, i) => {
                                            return (
                                                <span className={"fontFamilyHeadlines"}>{textLine} </span>
                                            )
                                        })}
                                        </h3>
                                    </div>
                                    {this.props.product.AUSLAUFARTIKEL == "Y" &&
                                        <div><h4><strong>(Ausgelaufen)</strong></h4></div>
                                    }
                                </Col>
                                <Col xs={12} md={12} className="d-none mt-0 mt-sm-2 d-sm-block">
                                    <IconBoxes icons={this.props.product.human_readable.icons}></IconBoxes>
                                </Col>
                            </Row>
                            }
                            {this.props.product.corporateIdentity.showPrice &&
                                <Row>
                                    <Col xs={12} md={12}>
                                        <div>
                                            <h5>
                                                {productTitleTextLines.map((textLine, i) => {
                                                    return (
                                                        <span style={{letterSpacing:"1px"}} className={"fontFamilyHeadlines"}>{textLine} </span>
                                                    )
                                                })}
                                            </h5>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12} className="mb-0 mb-0 mb-sm-2">
                                        <h1 className={"pricetag"}>{this.props.product.human_readable.price.priceConsumerBeautified}</h1>
                                    </Col>
                                    <Col xs={12} md={12} className="d-none d-sm-block">
                                        <IconBoxes icons={this.props.product.human_readable.icons}></IconBoxes>
                                        <Container style={{height:'4em'}}></Container>
                                    </Col>
                                </Row>
                            }
                        </Col>
                        {this.props.product.image_files.large.length > 0 &&
                            <Col xs={12} lg={8} md={7} className="mb-2 mb-sm-0">
                                <div style={{height: 400}}>
                                    <Swiper
                                        pagination={{
                                            type: "progressbar",
                                        }}
                                        navigation={true}
                                        modules={[Pagination, Navigation]}
                                        className="mySwiper"
                                    >
                                        {this.props.product.image_files.large.map(function (object, idx) {
                                            return (<SwiperSlide>
                                                <img src={object}/>
                                                <img src={object}/>
                                                <img src={object}/>
                                            </SwiperSlide>);
                                        })}
                                    </Swiper>
                                </div>
                            </Col>
                        }
                        <Col className="d-block d-sm-none">
                            <Container style={{marginBottom:'4em'}}>
                                <IconBoxes icons={this.props.product.human_readable.icons}></IconBoxes>
                            </Container>
                        </Col>
                        <Col xs={12} lg={this.props.product.image_files.large.length > 0?4:12} md={5} >
                            <Tabs
                                defaultActiveKey="alternative_sizes"
                                id="uncontrolled-tab-example"
                                className="mb-3 sizestabsColumn"
                            >
                                <Tab className="product_varations" eventKey="alternative_sizes" title="Alternative Größen">
                                    <div className="sizestabsColumn clearfix">
                                        <ul className={"product_varations"}>
                                            {this.props.product.product_variations.alternative_sizes.map((alternativeSizeObj, i) => {
                                                if(uniqueId == alternativeSizeObj.kdb_id){
                                                    return <li><a className="selected">
                                                        {alternativeSizeObj.text}
                                                    </a>
                                                    </li>
                                                }else{
                                                    return <li><a href={alternativeSizeObj.url}>
                                                        {alternativeSizeObj.text}
                                                    </a>
                                                    </li>
                                                }
                                            })}</ul>
                                    </div>
                                </Tab>
                                <Tab className="product_varations" eventKey="dekor_zubehoer" title="Dekor Zubehör">
                                    <ul>
                                        {this.props.product.product_variations.decor_and_extensions.map((alternativeSizeObj, i) => {
                                            if(uniqueId == alternativeSizeObj.kdb_id){
                                                return <li><a className="selected">
                                                    {alternativeSizeObj.text}
                                                </a>
                                                </li>
                                            }else{
                                                return <li><a href={alternativeSizeObj.url}>
                                                    {alternativeSizeObj.text}
                                                </a>
                                                </li>
                                            }
                                        })}</ul>
                                </Tab>
                            </Tabs>
                            <div style={{clear:'both'}}></div>
                            <div className={"productId"}>
                                <FontAwesomeIcon icon={faQrcode} /> KDB-ID <span>{this.props.product.uniqueId}</span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}


export default Product;