import React, {Component, useState} from 'react';


import config from "../config";
import {Navigate} from "react-router-dom";
import logo from "../assets/kdb-logo.jpeg";
import loading from "../assets/loading.gif";
import shoppingSearch from "../assets/shopping_search.jpg";


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


// import required modules
import { Pagination, Navigation } from "swiper";
// or less ideally
import {Button, Col, Container, Row, Stack, Tab, Tabs} from 'react-bootstrap';
import IconBoxes from "../components/IconBoxes";
import Product from "../components/Product";
import PageLayout from "./PageLayout";
import PagePopup from "../components/PagePopup";

import styled from 'styled-components';



class ProductPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: true,
            product : null,
            productId: props.productId
        };
    }

    async componentDidMount() {
        try {
            const product_id = encodeURIComponent(this.state.productId);
            const params = new URLSearchParams({
                productId: product_id
            });
            let response = await fetch(config.API_HOST_URL + "/products/single?" + params.toString());
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const json = await response.json();
            this.setState({
                isFetching: false,
                product : json
            });
        } catch (error) {
            console.log(error)
            this.setState({
                isFetching: true,
                product : null
            });
        }
    }





    render() {
        if(!this.state.isFetching && this.state.product == null){
            return <Navigate to="/289MW6RE1N" />
        }

        console.log(this.state.product)

        return (
            this.state.product == null && this.state.isFetching? (
                    <div className="App">
                        <header className="App-header">
                            <img src={loading} className="loadingImage" />
                        </header>
                    </div>
            ):(


                <PageLayout
                    corporateIdentity={this.state.product.corporateIdentity}
                    backgroundColor={this.state.product.corporateIdentity.backgroundColorPrimary}
                    footerMenuBackgroundColor={this.state.product.corporateIdentity.backgroundColorSecondary}
                    footerImprintBackgroundColor={this.state.product.corporateIdentity.backgroundColorTertiary}
                    footerText={this.state.product.corporateIdentity.footerText}
                >
                    <Product product={this.state.product}></Product>
                    {this.state.product.AUSLAUFARTIKEL == "J" &&
                    <div><PagePopup
                        showFooter={false}
                        title={"Artikel ausgelaufen"}
                        body={[
                            "Leider ist der Artikel ",
                            <b>{this.state.product.human_readable.verschluesselter_text}</b>,
                            " ausgelaufen.",
                            " Bitte sprechen Sie unser Personal an ob ggf. noch Restbestände zur Verfügung stehen.",
                            <img style={{width:'100%',marginTop:'1em'}} src={shoppingSearch}/>
                        ]}
                        show={true}></PagePopup></div>
                    }
                </PageLayout>
                    )
        );
    }
}


export default ProductPage;