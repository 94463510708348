import './App.css';


import ProductPage from "./pages/ProductPage";

import React, {Component} from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link, useParams, useLocation
} from "react-router-dom";
import PageAbout from "./pages/PageAbout";
import PageNotFound from "./pages/PageNotFound";
import PageLayout from "./pages/PageLayout";
import PageValidate from "./pages/PageValidate";



class App extends Component {

  constructor(props) {
    super(props);

  }



  render() {
    return (
        <Router>
          <Routes>
            <Route path="/notfound" element={<PageNotFound/>}/>
            <Route path="/validate" element={<PageValidate />} />
            <Route path="/:productId" element={<QRCode />} />
            <Route path="/" element={<PageLayout><PageAbout /></PageLayout>} />
            <Route path="*" element={<PageLayout><PageNotFound/></PageLayout>}/>
          </Routes>
        </Router>

    );
  }
}


function QRCode() {
  const location = useLocation();
  const params = useParams();
  return <>
    <ProductPage key={params.productId} productId={params.productId}/>
  </>;
}



export default App;
