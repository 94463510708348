const config = {
    //API_HOST_URL: "http://192.168.0.109:8080",
    API_HOST_URL: "https://api.k-db.de",

    PRODUCT_KEYS_MAPPING: {
        manufacturer_id : 'HERSTELLER',
        productgroup_id : 'SERIENNAME',
        productsubgroup_id : 'HERSTELLER_FARBE',
        product_id : 'ARTIKELNUMMER'
    },
};

export default config;
