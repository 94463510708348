import React, { Component } from 'react';
import logo from "../assets/logo.svg";
import PageLayout from "./PageLayout";

class PageNotFound extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
                <div className="App">
                    <header className="App-header">
                        <img src={logo} className="App-logo" style={{maxWidth:'10em'}} alt="logo" />
                        <p>
                            Inhalt nicht gefunden
                        </p>
                        <a
                            className="App-link"
                            href="https://www.k-db.de"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            www.k-db.de
                        </a>
                    </header>
                </div>
        )
    }
}


export default PageNotFound;