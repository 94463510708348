import React, { Component } from 'react';
import {Link} from "react-router-dom";
import HtmlReactParser from 'html-react-parser';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faExternalLink, faQrcode
} from '@fortawesome/free-solid-svg-icons'


class PageLayout extends Component {

    constructor(props) {
        super(props);
    }

    LightenDarkenColor(col,amt) {
        var usePound = false;
        if ( col[0] == "#" ) {
            col = col.slice(1);
            usePound = true;
        }
        var num = parseInt(col,16);
        var r = (num >> 16) + amt;
        if ( r > 255 ) r = 255;
        else if  (r < 0) r = 0;
        var b = ((num >> 8) & 0x00FF) + amt;
        if ( b > 255 ) b = 255;
        else if  (b < 0) b = 0;
        var g = (num & 0x0000FF) + amt;
        if ( g > 255 ) g = 255;
        else if  ( g < 0 ) g = 0;

        return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
    }

    componentDidMount() {

        let cssCode = `
    
            @font-face {
                font-family: fontFamilyText;
                src: url('${this.props.corporateIdentity.fontFamilyText}')  format('truetype');
                font-weight: normal;
                font-style: normal;
            }
            @font-face {
                font-family: fontFamilyHeadlines;
                src: url('${this.props.corporateIdentity.fontFamilyHeadlines}')  format('opentype');
                font-weight: normal;
                font-style: normal;
            }
            
            .fontFamilyHeadlines {
                font-family: 'fontFamilyHeadlines' !important;
            }
            .fontFamilyText {
                font-family: 'fontFamilyText' !important;
            }
            h1,h2,h3,h4,h5{
                font-family: 'fontFamilyHeadlines' !important;
            }
            body,p,span,a{
                font-family: 'fontFamilyText' !important;
            }
            html,.root{
                font-family: 'fontFamilyText' !important;
                background: ${this.props.corporateIdentity.backgroundColorPrimary};
            }
            .primaryContainer{
                padding:1.5em;
                padding-top:2em;
              background: ${this.props.corporateIdentity.backgroundColorPrimary};
              color: ${this.props.corporateIdentity.fontColorPrimary};
            }
            .primaryContainer a{
              color: ${this.props.corporateIdentity.accentColorPrimary};
            }
            .primaryContainer a:hover{
              color: ${this.LightenDarkenColor(this.props.corporateIdentity.accentColorPrimary,150)};
            }
            
            .primaryContainer .swiper-pagination-progressbar .swiper-pagination-progressbar-fill{
              background: ${this.props.corporateIdentity.accentColorPrimary} !important;
            }
            
            .nav-tabs .nav-item.show .nav-link,
            .nav-tabs .nav-link.active{
              border-bottom: 2px solid ${this.props.corporateIdentity.accentColorPrimary} !important;
            }
            
            .
            .nav-link:hover {
              color: ${this.props.corporateIdentity.accentColorPrimary};
              border: 1px solid ${this.props.corporateIdentity.accentColorPrimary} !important;
            }
            .nav-link:hover {
              color: ${this.props.corporateIdentity.accentColorPrimary};
              border: 1px solid ${this.props.corporateIdentity.accentColorPrimary} !important;
            }
            
            .primaryContainer .swiper-button-next, .swiper-button-prev {
              color: ${this.props.corporateIdentity.accentColorPrimary} !important;
            }
            
            li.nav-item button{
              color: ${this.props.corporateIdentity.fontColorPrimary} !important;
            }
            li.nav-item button.active{
              color: ${this.props.corporateIdentity.accentColorPrimary} !important;
            }
            
            .productId span{
              background: none;
              color: ${this.props.corporateIdentity.accentColorPrimary};
            }
            
            .product_varations li a{
                border: 1px solid ${this.props.corporateIdentity.fontColorPrimary};
                border-radius: 1em;
                padding-left:1em;
                padding-right:1em;
               color: ${this.props.corporateIdentity.fontColorPrimary};
            }
            .product_varations li a:hover,
            .product_varations li a.selected{
                cursor:pointer;
                border: 1px solid ${this.props.corporateIdentity.accentColorPrimary};
                
               color: ${this.props.corporateIdentity.accentColorPrimary} !important;
            }
                            
            .secondaryContainer{
              background: ${this.props.corporateIdentity.backgroundColorSecondary};
              color: ${this.props.corporateIdentity.fontColorSecondary};
            }
            .secondaryContainer a{
              color: ${this.props.corporateIdentity.fontColorSecondary};
            }
            .secondaryContainer a:hover{
              color: ${this.props.corporateIdentity.accentColorSecondary};
            }
            
            .tertiaryContainer{
              background: ${this.props.corporateIdentity.backgroundColorTertiary};
              color: ${this.props.corporateIdentity.fontColorTertiary};
              
            }
            
            .tertiaryContainer a{
              color: ${this.props.corporateIdentity.accentColorTertiary};
            }
            .tertiaryContainer a:hover{
              color: ${this.LightenDarkenColor(this.props.corporateIdentity.accentColorTertiary,150)};
            }
        
        `
        const blob = new Blob([cssCode], { type: "text/css" });
        const url = URL.createObjectURL(blob);
        this.addStyle(url);
    }

    addStyle = url => {
        const style = document.createElement("link");
        style.href = url;
        style.rel = "stylesheet";
        style.type = "text/css";
        style.async = true;
        document.head.appendChild(style);
    };

    render() {
        return (
            <div className="page">
                <div className="content primaryContainer" >
                    {this.props.children}
                </div>
                <div className={"footer secondaryContainer"}>
                    <ul>
                        {this.props.corporateIdentity.footerMenuLinks.map((linkObject, i) => {
                            return (
                                <li>
                                    <Link target="_blank" to={linkObject.url}>
                                        <FontAwesomeIcon icon={faExternalLink} /> {linkObject.title}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="disclaimer tertiaryContainer" style={{backgroundColor:this.props.footerImprintBackgroundColor}}>
                    <div className={"centeredTextContainer"}>
                    { HtmlReactParser (this.props.footerText) }
                    </div>
                </div>
            </div>
        )
    }
}


export default PageLayout;