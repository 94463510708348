import React, { Component } from 'react';
import logo from "../assets/logo.png";
import CSVFileValidator from "csv-file-validator";
import {Alert, Button, Col, OverlayTrigger, Row} from "react-bootstrap";
import './PageValidate.css'
import { faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
/* https://github.com/shystruk/csv-file-validator */
const ValidationController = require('../controller/ValidationController');


const excelColumnIndexToNumber = function(val) {
    try{
        var base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', i, j, result = 0;
        for (i = 0, j = val.length - 1; i < val.length; i += 1, j -= 1) {
            result += Math.pow(base.length, j) * (base.indexOf(val[i]) + 1);
        }
        return result;
    }catch (e) {
        return -1
    }

};

class PageValidate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            csvData: {
                inValidData:[],
                data:[]
            }
        };
        this.handleFileChange = this.handleFileChange.bind(this);
        this.validate = this.validate.bind(this);
        let validationController = new ValidationController();
        this.CSVConfig = validationController.getCSVConfig();
    }

    async handleFileChange(event) {
        this.state.file = event.target.files[0];
        this.state.csvData = null;
        await this.validate();
    }

    async validate() {
        this.setState({csvData:null});
        let csvData = await CSVFileValidator(this.state.file, this.CSVConfig);
        this.setState({csvData:csvData});
    }



    getAllErrors(){
        let errorMap = {};
        this.state.csvData.inValidData.forEach((element) => {
            if(errorMap[element.rowIndex] === undefined){
                errorMap[element.rowIndex] = {
                    data: this.state.csvData.data[element.rowIndex - 2],
                    errors: []
                };
            }
            errorMap[element.rowIndex].errors.push(element);
        });
        return errorMap;

    }


    renderHeaderTrRow(csvData){
        return (
            <tr>
                <td><span style={{padding:'0.5em'}}><b>Index</b></span></td>
                <td><span style={{padding:'0.5em'}}><b>Status</b></span></td>
                {Object.keys(csvData.data[0]).map(function (columnName, idx2) {
                    return(<td><span style={{padding:'0.5em'}}><b>{columnName}</b></span></td>)
                })}
            </tr>
        );
    }

    renderDataRows(csvData,errorMap){
        return (
            <>
                {
                    csvData.data.map(function (row, idx) {

                        return (<tr>
                            <td>{idx}</td>
                            <td>OK</td>
                        {Object.keys(csvData.data[idx]).map(function (columnName, idx2) {
                            return(<td className={"valid"}><span style={{padding:'0.5em'}}><b>{csvData.data[idx][columnName]}</b></span></td>)
                        })}
                        </tr>)
                    })
                }
            </>

        );
    }

    renderErrorRows(errorMap){
        return (
            Object.keys(errorMap).map(function (errorObj, idx) {
                return (
                    <tr>
                        <td><span style={{padding:'0.5em'}}><b>{errorObj}</b></span></td>
                        <td style={{textAlign:'left'}}>
                            {errorMap[errorObj].errors.map(function (error, idx2) {
                                return(<div style={{display:'inline-block',marginRight:'0.5em'}}>
                                    <OverlayTrigger placement="right" overlay={
                                        <div className={"error_tooltip"}>
                                            <div className={"index"}>Zeile: {error.rowIndex}, Spalte: {error.columnIndex}/{excelColumnIndexToNumber(error.columnIndex)}</div>
                                            <div className={"message"}>{error.message}</div>
                                        </div>

                                    }>
                                        <Button variant="danger"><FontAwesomeIcon icon={faTriangleExclamation} /></Button>
                                    </OverlayTrigger>
                                </div>)
                            })}
                        </td>
                        {errorMap[errorObj].data !== undefined &&
                            Object.keys(errorMap[errorObj].data).map(function (columnName, idx2) {
                                let errorIndices = [];
                                errorMap[errorObj].errors.forEach(async (error) => {
                                    errorIndices.push(excelColumnIndexToNumber(error.columnIndex)-1);
                                });
                                return(<td className={errorIndices.includes(idx2)?"invalid":"valid"}><span></span>
                                    <span>{errorMap[errorObj].data[columnName]}</span></td>)
                            })
                        }
                    </tr>);
            })
        );

    }

    render() {
        try{
            let self = this;

            if(this.state.csvData == null) {
                return <div className="App">
                    <header className="App-header">
                        <img src={logo} style={{maxWidth:'10em',padding:'1em'}} />
                        <h2>CSV-Datei wird validiert ...</h2>
                    </header>
                </div>
            }

            if(this.state.file == null) {
                return <div className="App">
                    <div align={"center"}>
                        <img src={logo} style={{maxWidth:'10em',padding:'1em'}} />
                    </div>
                    <form>
                        <h1>Hersteller Produktdatenbank (.csv) validieren</h1>
                        <input type="file" onChange={this.handleFileChange}/>
                    </form>
                </div>
            }

            let errorMap = this.getAllErrors();


            return (
                <div className="App">
                    <div align={"center"}>
                        <img src={logo} style={{maxWidth:'10em',padding:'1em'}} />
                    </div>
                    <form>
                        <h1>Hersteller Produktdatenbank (.csv) validieren</h1>
                        <input type="file" onChange={this.handleFileChange}/>
                    </form>
                    <div>
                        {this.state.csvData.data[0] !== undefined &&

                            <>
                            {Object.keys(errorMap).length > 0 ?
                                <div>
                                    <Alert key={'danger'} variant={'danger'}>
                                        Validierung fehlgeschlagen! Es gibt noch <b>{Object.keys(errorMap).length} Fehler</b> zu korrigieren.
                                    </Alert>
                                    <table>
                                        {this.renderHeaderTrRow(this.state.csvData)}
                                        {this.renderErrorRows(errorMap)}
                                    </table>
                                </div>
                                :
                                <div>
                                    <Alert key={'success'} variant={'success'}>
                                        Großartig! Alle Daten-Zellen haben das korrekte Format.
                                    </Alert>
                                    <table>
                                        {this.renderHeaderTrRow(this.state.csvData)}
                                        {this.renderDataRows(this.state.csvData,errorMap)}
                                    </table>
                                </div>
                            }
                            </>

                        }
                    </div>
                </div>
            )
        }catch (e) {
            return("Leider ist ein Fehler aufgetreten: "+JSON.stringify(e));
        }

    }
}


export default PageValidate;